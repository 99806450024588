<template>
  <div class="wrap">
		<div class="info" v-if="page == 1">
			<ul class="info-top">
				<li>
					<div>识别编号:</div>
					<div><input v-model="QAnumber" type="number" placeholder="请输入识别编号" pattern="[0-9]*" @input="onNumber"></div>
					<!-- <div><input v-model="QAnumber" type="text" placeholder="请输入识别编号"></div> -->
				</li>
				<li>
					<div>评估日期:</div>
					<div><input v-model="QAdate" type="text" placeholder="请输入评估日期" maxlength="8" oninput="value=value.replace(/[^\d]/g,'')" readonly @click="dateShow = true"></div>
				</li>
			</ul>
			
			<!-- 评估日期 -->
			<van-calendar class="mank-calendar" v-model="dateShow" @confirm="onConfirm" />

			<div class="info-title">{{info.title}}</div>
			<div class="info-btn" @click="toStart"><img src="../assets/img/QA/qa-start-btn.png"></div>
		</div>
		<div class="QA-wrap" v-else>
			<div class="title">{{info.title}}</div>
			<div class="info-QA" v-if="!result.state">
				<div>
					<div class="info-Q">{{question.question}}</div>
					<ul class="info-A">
						<li :class="item.on ? 'on' : ''" v-for="(item, index) in question.option_info" :key="index" @click="optionClick(index)">{{item.option}}</li>
					</ul>
					<img class="info-QA-icon" v-if="lastState" src="../assets/img/QA/qa-icon.png" alt="">
					<div class="info-QA-btn">
						<div @click="prevQ"><img src="../assets/img/QA/qa-prev.png"></div>
						<div @click="nextQ" v-if="!lastState"><img src="../assets/img/QA/qa-next.png"></div>
						<div @click="submit" v-else><img src="../assets/img/QA/qa-submit.png"></div>
					</div>
				</div>
			</div>
			<div v-else>

				<!-- 答题结果 -->
				<div class="result" ref="imageWrapper" v-if="!imgUrl">
					<img class="result-bg" src="../assets/img/QA/qa-result.png" alt="">
					<div class="result-wrap">
						<div class="result-title">你的<br>{{info.title}}<br>计算结果:</div>
						<div class="result-num">总分值：<div><span>{{result.total}}</span><span>分</span></div></div>
						<div class="result-tip">{{info.explain}}</div>
						<div class="result-data">
							<div>识别编号:{{QAnumber}}</div>
							<div>评估日期:{{QAdate}}</div>
						</div>
					</div>
				</div>

				<!-- 答题结果海报 -->
				<div class="poster" v-if="result.state && imgUrl">
					<div class="poster-img">
						<img :src="imgUrl" alt="">
					</div>
					<img class="tip-btn" src="../assets/img/download.png" alt="">
					<div class="back" @click="afresh">重新计算 ></div>
				</div>
			</div>
		</div>

		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
	</div>
</template>

<script>
import Vue from "vue"
import html2canvas from "html2canvas"
import Reference from '../components/Reference'
import { Toast, RadioGroup, Radio, Popup, Calendar  } from 'vant'
import { getQuestionList } from '../common/api/index'
Vue.use(Radio).use(RadioGroup).use(Popup).use(Calendar)
export default {
  data() {
    return {
			page: 1, // 1为首页，2为答题页
			QAnumber: '',
			QAdate: '',
			dateShow: false, // 日历
			imgUrl: '',
      QIndex: 0,
			question: {},
      lastState: false, // 最后一题
      list: [], // 初始题目顺序
			info: {},
			result: {
				state: false,
				total: 0
			},
			refInfo: ""
		};
  },
	components: {
		Reference
	},
  created() {
		this.id = this.$route.query.id
		this.findQA()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 5, category_id: this.id, questionnaire_id: this.id, page: this.$route.path})
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
  methods: {
		/**
		 * 获取问卷详情
		 */
		findQA () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getQuestionList({questionnaire_id: that.id}).then(res => {
				Toast.clear()
				// console.log(res)
				if (res.code === 200) {
					let lists = res.data.question_list
					lists.forEach(function (value) {
            value.option_info.forEach(function (option) {
              option.on = false
            })
          })
					that.lists = lists
          that.question = that.lists[that.QIndex]
					that.info = res.data.questionnaire_info
					that.refInfo = res.data.questionnaire_info.reference
				} else {
					Toast(res.msg)
				}
			})
		},
		
		/**
		 * 日期格式
		 */
		formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },

		/**
		 * 选择日期
		 */
    onConfirm(date) {
      this.dateShow = false
      this.QAdate = this.formatDate(date)
    },

		/**
		 * 输入编号
		 */
		onNumber () {
			console.log(this.QAnumber)
			if (this.QAnumber.length > 4) {
				this.QAnumber = this.QAnumber.slice(0, 4)
			}
			if (this.QAnumber < 1 && this.QAnumber) {
				this.QAnumber = 1
			}
		},

		/**
		 * 开始答题
		 */
		toStart () {
			// if (this.QAnumber && this.QAdate) {
				this.page = 2
			// } else {
				// Toast('请输入编号和日期')
			// }
		},

		/**
		 * 选择选项
		 * index => 选项下标
		 * type => 题目类型 1单选 2多选 3填空 4滑动 5嵌套 6下拉
		 */
		optionClick (index) {
			// console.log(this.lists[QIndex])
			let option = this.question.option_info
			if (this.question.type == 1) {
				option.forEach(function (value) {
          value.on = false
        })
			}
			// console.log(this.question)
      this.question.option_info[index].on = true
		},

    /*
    * 下一题
    */
    nextQ () {
      let state = false
      let option = this.question.option_info
      option.forEach(function (value) {
        if (value.on) {
          state = true
        }
      })
      if (state) {
        this.QIndex++
        this.question = this.lists[this.QIndex]
				if (this.QIndex == this.lists.length - 1) {
					this.lastState = true
				}
				// console.log(this.lists)
      } else {
        Toast('请选择答案')
      }
    },

    /*
    * 上一题
    */
    prevQ () {
			// console.log(this.QIndex)
			if (this.QIndex === 0) {
				this.page = 1
			} else if (this.QIndex === 1) {
        this.QIndex = 0
        this.question = this.lists[this.QIndex]
      } else {
        this.lastState = false
        this.QIndex--
        this.question = this.lists[this.QIndex]
      }
    },

		/**
		 * 计算
		 */
		submit () {
			let total = 0
			let QNum = 0
			this.lists.forEach(function (value) {
				value.option_info.forEach(function (option) {
          if (option.on) {
						total += option.score
						QNum++
					}
        })
			})

			if (QNum < this.lists.length) {
				Toast({
					message: '请完善问卷',
					className: 'submit-tip'
				})
				return false
			}

			this.result.state = true
			this.result.total = total

			// 提交问卷答题记录
			this.$Common._record({openid: this.$store.state.openid, module_id: 5, category_id: this.id, questionnaire_id: this.id, page: this.$route.path})

			let that = this
			setTimeout(function () {
				that.createImg()
			}, 200)
		},

		/**
		 * 重新计算
		 */
		afresh () {
			this.page = 1 // 1为首页，2为答题页
			this.imgUrl = ''
      this.QIndex = 0
      this.lastState = false // 最后一题
			this.result.state = false
			this.result.total = 0
			
			this.$Common._record({openid: this.$store.state.openid, module_id: 5, category_id: this.id, questionnaire_id: this.id, page: this.$route.path})

			this.findQA()
		},
		
    /**
     * 生成结果
     */
    createImg () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			html2canvas(that.$refs.imageWrapper, {
				backgroundColor: null
			}).then(canvas => {
				Toast.clear()
				let dataURL = canvas.toDataURL("image/png")
				that.imgUrl = dataURL
				// console.log(that.imgUrl)
			}).catch(err => { /* eslint-disable-line */
				Toast.clear()
				// console.log(err)
			})
    }
	}
};
</script>

<style scoped lang="scss">
.mank-calendar{
	color: #333;
}
.info{
	width: 97%;
	height: 1016px;
	margin-top: 170px;
	background: url(../assets/img/QA/qa-cover.png) top center no-repeat #000;
	background-size: 100% 100%;
	
	.info-top{
		width: 63%;
		margin: auto;
		padding-top: 96px;
		margin-left: 25%;
		font-size: 24px;
		li{
			height: 50px;
			display: flex;
			align-items: center;

			&:last-child{
				margin-top: 20px;
			}

			div:last-child{
				height: 50px;
				flex: 1;
				position: relative;
				margin-left: 16px;

				&:after{
					content: '';
					width: 100%;
					height: 5px;
					position: absolute;
					bottom: 0;
					left: 0;
					background: url('../assets/img/select_line.png') top center no-repeat;
					background-size: 100% 100%;
				}

				input{
					width: 100%;
					background: transparent;
					user-select: text;
					-webkit-user-select: text;
				}
			}
		}
	}

	.info-title{
		width: 50%;
		font-size: 40px;
		text-align: center;
		margin: 140px 0 0 28%;
		position: relative;

		&::before{
			content: '';
			width: 88%;
			height: 69px;
			position: absolute;
			bottom: -69px;
			left: 26px;
			background: url(../assets/img/QA/qa-title-line.png) top center no-repeat;
			background-size: 100% 100%;
		}
	}

	.info-btn{
		width: 196px;
		margin: auto;
		margin-top: 200px;

		img{
			width: 100%;
		}
	}
}

.QA-wrap{
	padding-top: 0.1px;
}
.title{
	width: 90%;
	height: 80px;
	line-height: 80px;
	font-size: 40px;
	text-align: center;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 18px;
	border: 3px solid #FFFFFF;
	position: absolute;
	left: 5%;
	top: 100px;
	padding-top: 0.1px;
}
.info-QA{
	width: 97%;
	height: 1026px;
	margin-top: 228px;
	background: url(../assets/img/QA/qa-cover.png) top center no-repeat #000;
	background-size: 100% 100%;

	>div{
		width: 60%;
		height: 100%;
		margin-left: 25%;
		position: relative;

		.info-Q{
			font-size: 30px;
			padding: 70px 0 0 18px;
			position: relative;

			&::before{
				content: '';
				width: 88%;
				height: 69px;
				position: absolute;
				bottom: -50px;
				left: 0;
				background: url(../assets/img/QA/qa-title-line.png) top center no-repeat;
				background-size: 100% 100%;
			}
		}
		.info-A{
			font-size: 26px;

			li{
				display: flex;
				// align-items: center;
				margin: 36px 0 0 23px;

				&::before{
					content: '';
					width: 39px;
					height: 38px;
					background: url(../assets/img/QA/qa-select.png) top center no-repeat;
					background-size: 100% 100%;
					margin-right: 14px;
				}

				&:first-child{
					margin-top: 70px;
				}

				&.on{
					&::before{
						width: 39px;
						height: 38px;
						background: url(../assets/img/QA/qa-select-on.png) top center no-repeat;
						background-size: 100% 100%;
						margin-right: 14px;
					}
				}
			}
		}

		.info-QA-icon{
			width: 231px;
			position: absolute;
			right: -40px;
			bottom: 350px;
		}

		.info-QA-btn{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			left: 0;
			bottom: 250px;

			div{
				width: 196px;

				img{
					width: 100%;
				}
			}
		}
	}
}
.result{
	width: 90%;
	position: relative;
	margin: auto;
	margin-top: 229px;
	// background: #000;
	.result-bg{
		width: 100%;
	}

	.result-wrap{
		width: 90%;
		height: 90%;
		position: absolute;
		left: 5%;
		top: 5%;

		.result-num{
			color: #49FCFC;
			font-size: 32px;
			line-height: 32px;
			font-weight: 800;
			display: flex;
			align-items: center;
			margin: 150px 0 70px 0;

			div{
				display: flex;
				align-items: flex-end;
				margin-left: 20px;

				span:first-child{
					font-size: 120px;
					line-height: 120px;
				}
			}
		}

		.result-title{
			font-size: 32px;
			margin-top: 100px;
			line-height: 56px;
		}

		.result-tip{
			font-size: 24px;
		}

		.result-data{
			font-size: 20px;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}
	
// 海报
.poster{
	width: 90%;
	height: 90%;
	background: #000;
	// position: fixed;
	// top: 16%;
	// left: 5%;
	margin: auto;
	margin-top: 190px;
	// padding-bottom: 60px;
	margin-bottom: 60px;
	overflow-y: scroll;
	
	.poster-img{
		// min-height: 50%;
		margin-bottom: 20px;
	}

	img{
		width: 100%;
		pointer-events: initial;
	}

	.tip-btn{
		pointer-events: none;
	}

	.back{
		font-size: 32px;
		color: #FFDA00;
		text-align: center;
		margin-top: 20px;
	}
}
</style>
